// please run modalUpdate function after Modal contents can be changed.
// $(modal).modalUpdate(); // example
(function($) {
	$.fn.modalUpdate = function() {
		return this.each(function(dr) {
			var $el = $(this);
			var duration = dr ? dr : 300;
			// 'modal-locked' Classes must be added before contents can be changed.
			$el.addClass('modal-locked');
			// 'modal-locked' Classes must be removed after contents can be changed.
			setTimeout(function(){
				$el.removeClass('modal-locked');
			}, duration);
		});
	};
})(jQuery);

$(document).ready(function() {
	//var modal = document.getElementsByClassName('modal'); /*PJTWAUS-1 : 20191203 remove  */
	// modal focus
	$(document).on('shown.bs.modal', '.modal', function () { /*PJTWAUS-1 : 20191203 modify */
		var focusObj = $(this).find('a[href], area[href], input:not([disabled]), input:not([readonly]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, embed, *[tabindex], *[contenteditable]');
		var focusFirst = focusObj.first();
		var focusLast = focusObj.last();
		/* LGEUS-931 : 20200928 add*/
		if($("#modal_pdp_detail_gallery").length){
			$('.slider.thumbnail .item.image a').attr('tabindex',1); 
		}
		/* LGEUS-931 : 20200928 add*/
		focusFirst.focus();

		focusLast.on('keydown', function (e) {
			if ((e.which === 9 && !e.shiftKey)) {
				e.preventDefault();
				focusFirst.focus();
			}
		});
		focusFirst.on('keydown', function (e) {
			if ((e.which === 9 && e.shiftKey)) {
				e.preventDefault();
				focusLast.focus();
			}
		});

		$(this).find('.modal-all-close').off().on({
			click: function(){
				$('.modal').modal('hide');
			}
		})
	});
	/*PJTWAUS-1 : 20191203 add */
	console.log('');
	$(document).on('hidden.bs.modal', '#addToCartError, #addToCartSuccess, #modal_pdp_detail_gallery, #compare_alert', function () {
		console.log('');
		$(this).removeData('bs.modal');
		console.log('');
	});
	/*PJTWAUS-1 : 20191203 add */
});